<template>
  <div>
    <el-row style="margin-bottom: 20px">
      <el-button
        v-throttle
        size="medium"
        @click="
          () => {
            this.$router.back();
          }
        "
        >返回</el-button
      >
      <!-- @click="addClassRoom" -->
      <el-button
        v-throttle
        icon="el-icon-circle-plus-outline"
        type="primary"
        size="medium"
        @click="dialogVisibleBtn('add')"
        >添加</el-button
      >
    </el-row>
    <!--表格  -->
    <el-table
      ref="multipleTable"
      :data="schoolClassList"
      style="width: 100%"
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      stripe
    >
      <!-- <el-table-column label="日期" width="120">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>-->
      <el-table-column align="center" prop="sysSchoolYearDicId" label="学年">
        <template slot-scope="scope">
          {{ sysSchoolYearDicFilters(scope.row.sysSchoolYearDicId) }}
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" prop="sysPeriodDicId" label="学段">
        <template slot-scope="scope">
          {{ sysSemesterTermDicFilters(scope.row.sysPeriodDicId) }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="sysSchoolClassGradeId" label="年级">
        <template slot-scope="scope">
          {{
            sysSemesterTermGradeDicListFilters(scope.row.sysSchoolClassGradeId)
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="num"
        label="班级编号"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="班级名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="studentCount"
        label="班级人数"
      ></el-table-column>
      <!-- width="300px" -->
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            v-throttle
            size="mini"
            type="success"
            icon="el-icon-edit"
            @click="dialogVisibleBtn('update', scope.row)"
            >编辑</el-button
          >
          <el-button
            v-throttle
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="delCard(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.pageIndex"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.pageTotal"
    ></el-pagination>
    <!-- 弹框 -->
    <!-- 编辑班级信息 -->
    <el-dialog
      :title="dialogVisibleTit"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form
        :model="dialogVisibleData"
        :rules="rules"
        ref="dialogVisibleData"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="addAll">
          <el-form-item label="学年" prop="sysSchoolYearDicId">
            <el-select
              size="small"
              v-model="dialogVisibleData.sysSchoolYearDicId"
              placeholder="请选择学年"
              style="width: 100%"
            >
              <el-option
                v-for="item in yearList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="学段">
            <el-select
              size="small"
              v-model="dialogVisibleData.sysSchoolYearDicId"
              placeholder
              style="width: 100%"
            >
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="学段" prop="sysPeriodDicId">
            <el-select
              size="small"
              style="width: 100%"
              v-model="dialogVisibleData.sysPeriodDicId"
              placeholder="请选择"
              @change="handleChangePeriod"
            >
              <el-option
                v-for="item in periodList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="sysSchoolClassGradeId">
            <el-select
              size="small"
              style="width: 100%"
              v-model="dialogVisibleData.sysSchoolClassGradeId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in gradeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="年级" prop="sysSchoolClassGradeId">
            <el-cascader
              size="small"
              style="width: 100%"
              v-model="dialogVisibleData.sysSchoolClassGradeId"
              :options="periodList"
              @change="handleChange"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="班级名称" prop="name">
            <el-input
              v-model="dialogVisibleData.name"
              placeholder
              size="small"
              maxlength="9"
            ></el-input>
          </el-form-item>
          <el-form-item label="班级编号" prop="num">
            <el-input
              v-model="dialogVisibleData.num"
              placeholder
              size="small"
              maxlength="9"
              onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="班级人数" prop="studentCount">
            <el-input
              v-model="dialogVisibleData.studentCount"
              placeholder
              size="small"
              maxlength="4"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="uploadBtn('dialogVisibleData')"
            >保 存</el-button
          >
          <el-button v-throttle size="medium" @click="dialogVisible = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSchoolClass,
  getSchoolClassPage,
  delSchoolClass,
  addClass,
  editClassInfo,
} from "@/api/mechanism/examSchool.js";
export default {
  // 考点管理
  name: "classEditing",
  data() {
    const yearsRule = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年份不能为空"));
      }
      setTimeout(() => {
        if (value < 2018) {
          callback(new Error("请填写实际年份"));
        } else if (value > 2030) {
          callback(new Error("请填写实际年份"));
        } else {
          callback();
        }
      }, 100);
    };
    const numberRule = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("编号不能为空"));
      }
      setTimeout(() => {
        if (!this.isNumber(value)) {
          callback(new Error("编号必须为数字"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      sysOrgSchoolId: "",
      schoolClassList: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      options: [],
      dialogVisibleTit: "新增",
      dialogVisible: false,
      //班级信息
      dialogVisibleData: {
        // 班级名称
        name: "",
        // 班级编号
        num: 0,
        // status: 0,
        statusOccupied: 0,
        // 学生总数
        studentCount: 0,
        sysOrgSchoolClassId: 0,
        // 学校id
        sysOrgSchoolId: 0,
        // 年级
        sysSchoolClassGradeId: 0,
        // 学年
        sysSchoolYearDicId: 0,
        // 学段
        sysPeriodDicId: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入班级名称", trigger: "blur" }],
        num: [{ required: true, message: "请输入班级编号", trigger: "blur" }],
        studentCount: [
          { required: true, message: "请输入学生总数", trigger: "blur" },
        ],
        sysSchoolYearDicId: [
          { required: true, message: "请选择学年", trigger: "change" },
        ],
        sysPeriodDicId: [
          { required: true, message: "请选择学段", trigger: "change" },
        ],
        sysSchoolClassGradeId: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
      },
      yearList: [],
      periodList: [], // 学段
      gradeList: [], // 年级
    };
  },
  async created() {
    const { dataArr } = this.$store.state.public_data;
    await Promise.all(dataArr.map((e) => this.$store.dispatch("loadData", e)));

    console.log(this.$store.state.public_data);
    // 学年
    this.yearList = this.$store.state.public_data.sysAcademicYearDicList;
    // 学段
    this.periodList = this.$store.state.public_data.sysSemesterTermGradeDicList;
  },
  mounted() {
    this.sysOrgSchoolId = Number(this.$route.query.sysOrgSchoolId);
    this.getSchoolClassFunc(this.sysOrgSchoolId);
  },
  computed: {
    // gradeList() {
    //   let result = [];
    //   this.periodList.forEach((item) => {
    //     if (item.value === this.dialogVisibleData.sysPeriodDicId) {
    //       result = item.children;
    //     }
    //   });
    //   return result;
    // },
  },
  methods: {
    getSchoolClassFunc(data) {
      let fd = new FormData();
      fd.append("sysOrgSchoolId", data);
      getSchoolClassPage(this.page.pageIndex, this.page.pageSize, fd)
        .then((res) => {
          console.log("res", res);
          if (res.success) {
            this.schoolClassList = res.data;
            this.page.pageTotal = res.total;
          }
        })
        .catch((err) => {});
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.page.pageSize = val;
      this.page.pageIndex = 1;
      this.getSchoolClassFunc(this.sysOrgSchoolId);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page.pageIndex = val;
      this.getSchoolClassFunc(this.sysOrgSchoolId);
    },
    //删除班级
    delCard(value) {
      this.$confirm("确定删除该班级吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let classList = [];
          classList.push(value.sysOrgSchoolClassId);
          delSchoolClass(classList).then((res) => {
            let { code, success, msg } = res;
            if (success) {
              this.$message.success("删除班级成功");
            } else {
              this.$message.error(msg);
            }
          });
        })
        .catch(() => {});
    },
    // 添加/编辑按钮
    dialogVisibleBtn(key, val) {
      console.log(key, val);
      switch (key) {
        case "add":
          this.dialogVisibleTit = "添加";
          this.dialogVisibleData = {
            name: "",
            num: "",
            studentCount: "",
            sysSchoolClassGradeId: "",
            sysSchoolYearDicId: "",
          };
          break;
        case "update":
          this.dialogVisibleTit = "编辑";
          let {
            name,
            num,
            studentCount,
            sysSchoolClassGradeId,
            sysSchoolYearDicId,
            sysOrgSchoolClassId,
            sysOrgSchoolId,
          } = val;
          this.periodList.forEach((it) => {
            it.children.forEach((item) => {
              if (item.value === sysSchoolClassGradeId) {
                this.gradeList = it.children;
              }
            });
          });
          // console.log( this.periodList)
          this.dialogVisibleData = {
            name,
            num,
            studentCount,
            sysSchoolClassGradeId,
            sysSchoolYearDicId,
            sysOrgSchoolClassId,
            sysOrgSchoolId,
          };
          break;
      }
      this.dialogVisibleType = key;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.dialogVisibleData.clearValidate();
      });
    },
    // 新增/编辑租户
    uploadBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const apiMap = {
            add: addClass,
            update: editClassInfo,
          };
          let params = {
            ...this.dialogVisibleData,
            sysOrgSchoolId: this.sysOrgSchoolId,
          };
          apiMap[this.dialogVisibleType](params)
            .then((res) => {
              if (res.success) {
                this.$message.success("保存成功");
                this.dialogVisible = false;

                this.getSchoolClassFunc(this.sysOrgSchoolId);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 学段选择清空年级
    handleChangePeriod(val) {
      this.dialogVisibleData.sysSchoolClassGradeId = "";
      this.periodList.forEach((it) => {
        if (it.value == val) {
          this.gradeList = it.children;
        }
      });
      this.$nextTick(() => {
        this.$refs.dialogVisibleData.clearValidate();
      });
    },
    sysSemesterTermGradeDicListFilters(key) {
      const item =
        this.$store.state.public_data.sysSemesterTermGradeDicList.find((e) =>
          e.children.some((x) => x.value === key)
        );
      return item ? item.children.find((e) => e.value === key)?.label : null;
    },
    sysSemesterTermDicFilters(key) {
      return this.$store.state.public_data.sysSemesterTermDicList.find(
        (e) => e.value === key
      )?.label;
    },
    sysSchoolYearDicFilters(key) {
      return this.$store.state.public_data.sysAcademicYearDicList.find(
        (e) => e.value === key
      )?.label;
    },
    handleChange(val){
      // console.log(val)
      this.dialogVisibleData.sysSchoolClassGradeId = val[1]
    },

    //判断是否为数字类型
    isNumber(num) {
      return /^[0-9]+.?[0-9]*$/.test(num);
    },
  },
};
</script>
<style lang="scss" scoped></style>
